import React from 'react';
import styled from 'styled-components';

const NotFound = () => (
  <Wrapper>
    <h1>404 Not Found</h1>
    <p>お探しのページは見つかりませんでした。</p>
  </Wrapper>
);

const Wrapper = styled.div`
  text-align: center;
`;

export default NotFound;
